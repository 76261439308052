import { MANAGER_SCOPES } from "../../assets/variables";

export const RentalRoutes = [
    {
        // 관리자 - 설비 목록
        path: "/console/rental-subjects",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/rental/RentalSubjectList.vue"),
        props: true,
        scope: ["console", MANAGER_SCOPES.rentalSubjects.value],
    },
    {
        // 관리자 - 설비 목록
        path: "/console/rental-subjects/:type",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/rental/RentalSubjectList.vue"),
        props: true,
        scope: ["console", MANAGER_SCOPES.rentalSubjects.value],
    },
    {
        // 관리자 - 예약요청 목록
        path: "/console/rental-requests",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/rental/RentalRequestList.vue"),
        props: true,
        scope: ["console", MANAGER_SCOPES.rentalRequests.value],
    },
    {
        // 관리자 - 예약요청 목록
        path: "/console/rental-requests/:subjectType",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/rental/RentalRequestList.vue"),
        props: true,
        scope: ["console", MANAGER_SCOPES.rentalRequests.value],
    },
    {
        // 관리자 - 예약제한 목록
        path: "/console/rental-limitations",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/rental/RentalLimitationList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 예약제한 목록
        path: "/console/rental-limitations/:type",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/rental/RentalLimitationList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 예약차단 목록
        path: "/console/rental-blockages",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/rental/RentalBlockageList.vue"),
        props: true,
        scope: ["console", MANAGER_SCOPES.rentalBlockage.value],
    },
    {
        // 관리자 - 예약차단 목록
        path: "/console/rental-blockages/:targetType",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/rental/RentalBlockageList.vue"),
        props: true,
        scope: ["console", MANAGER_SCOPES.rentalBlockage.value],
    },
    {
        // 관리자 - 예약금지 목록
        path: "/console/rental-bans",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/rental/RentalBanList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 예약금지 목록
        path: "/console/rental-bans/:targetType",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/rental/RentalBanList.vue"),
        props: true,
        scope: ["console"],
    },

    {
        // 관리자 - 설비통계 - 총괄
        path: "/console/rental-statistics/summary",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/rental/statistics/RentalStatisticsSummary.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 설비통계 - 설비
        path: "/console/rental-statistics/subject",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/rental/statistics/RentalStatisticsSubject.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 설비통계 = 사용자
        path: "/console/rental-statistics/by-user",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/rental/statistics/RentalStatisticsByUser.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 설비통계 - 시간대
        path: "/console/rental-statistics/by-time",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/rental/statistics/RentalStatisticsByTime.vue"),
        props: true,
        scope: ["console"],
    },
];
