import { RENTAL_SUBJECT_TYPES } from "@/assets/variables";
import { RENTAL_TYPES } from "../constants";

let hours = new Array(24).fill().map((_, index) => index);

export const initRentalBusinessTime = (time = {}) => ({
    ...time,

    day: time.day ?? null,
    timeEnd: time.timeEnd ?? null,
    timeStart: time.timeStart ?? null,
});

export const initRentalSubject = (subject = {}) => ({
    ...subject,

    _thumb: subject._thumb ?? null,
    thumb: subject.thumb ?? null,

    _photos: [...(subject._photos || [])],
    photos: [...(subject.photos || [])],

    type: subject.type ?? RENTAL_SUBJECT_TYPES.FACILITIES.value,

    name: subject.name ?? null,
    code: subject.code ?? null,
    desc: subject.desc ?? null,
    place: subject.place ?? null,

    location: subject.location ?? null,
    capacity: subject.capacity ?? null,
    dimension: subject.dimension ?? null,
    equipment: subject.equipment ?? null,
    guidenote: subject.guidenote ?? null,

    index: subject.index ?? null,
    shows: subject.shows ?? false,

    rental: {
        ...(subject.rental || {}),

        type: subject.rental?.type ?? RENTAL_TYPES.BY_HOUR.value,

        businessDays: [...(subject.rental?.businessDays || [1, 2, 3, 4, 5, 6])].sort(),
        businessHours: [...(subject.rental?.businessHours || [9, 10, 11, 13, 14, 15, 16, 17])],
        businessTimes: [...(subject.rental?.businessTimes || [])].map(initRentalBusinessTime),

        holidays: [...(subject.rental?.holidays || [])].map((date) => date?.toDate?.() ?? date),
        isActive: subject.rental?.isActive ?? false,
    },
});
