export const RENTAL_LIMITATION_TARGET_TYPES = {
    ENTIRE_ASSET: { value: "ENTIRE_ASSET", text: "전체" },
    SUBJECT_TYPE: { value: "SUBJECT_TYPE", text: "설비유형" },
    SUBJECT_SELF: { value: "SUBJECT_SELF", text: "공간" },
};

export const RENTAL_LIMITATION_TYPES = {
    PER_WEEK: { value: "PER_WEEK", text: "주별제한" },
    PER_DATE: { value: "PER_DATE", text: "일별제한" },
};

export const RENTAL_TYPES = {
    BY_TIME: { value: "BY_TIME", text: "타임제" },
    BY_HOUR: { value: "BY_HOUR", text: "시간제" },
    BY_DATE: { value: "BY_DATE", text: "종일제" },
};

export const RENTAL_SUBJECT_TYPES = {
    EQUIPMENTS: { value: "EQUIPMENTS", text: "장비" },
    FACILITIES: { value: "FACILITIES", text: "시설" },
};

export const RENTAL_SCHEDULE_STATES = {
    APPLIED: { value: "APPLIED", text: "승인대기" },
    CANCELED: { value: "CANCELED", text: "신청취소" },
    APPROVED: { value: "APPROVED", text: "사용승인" },
    REJECTED: { value: "REJECTED", text: "사용거절" },
};
