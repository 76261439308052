import { ArchivingRoutes } from "./archiving.router";
import { AttendanceRoutes } from "./attendance.router";
import { BannerRoutes } from "./banner.router";
import { BoardRoutes } from "./board.router";
import { CenterRoutes } from "./center.router";
import { FormRoutes } from "./form.router";
import { MessageRotues } from "./message.router";
import { PeopleRoutes } from "./people.router";
import { PopupRoutes } from "./popup.router";
import { ProgramRoutes } from "./program.router";
import { RentalRoutes } from "./rental.router";
import { SettingRoutes } from "./setting.router";
import { StoryRoutes } from "./story.router";
import { TermRoutes } from "./term.router";
import { UserBansRoutes } from "./user-bans.router";
import { UserRoutes } from "./user.router";
import { AboutRoutes } from "./about.router";

export const ConsoleRoutes = [
    {
        // 관리자 메인
        path: "/console",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/Main.vue"),
        props: true,
    },
    ...ArchivingRoutes,
    // ...AttendanceRoutes,
    // ...BannerRoutes,
    ...BoardRoutes,
    ...CenterRoutes,
    // ...FormRoutes,
    // ...MessageRotues,
    ...PeopleRoutes,
    ...PopupRoutes,
    // ...ProgramRoutes,
    ...RentalRoutes,
    ...SettingRoutes,
    ...StoryRoutes,
    ...TermRoutes,
    // ...UserBansRoutes,
    ...UserRoutes,
    // ...AboutRoutes
];
